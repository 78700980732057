import getClendarPrice from "@/lib/data-service/flight/buyer_dom_white_flight_lowestPrice";
export default {
    name: "Calendar",
    data() {
        return {
            weekBtnStatu: true,
            weekList: [],
            calendarStatu: false,
            show: true,
            fullCalendarShow: false,
            airData: {},
            _saveWeek: "",
            events: []
        };
    },
    created() {
        window.addEventListener("click", this.cutShow);
    },
    destroyed() {
        window.removeEventListener("click", this.cutShow, false);
    },
    props: {
        typePage: {
            type: Boolean,
            default: false
        },
        saveWeek: String,
        initStatu: Number
    },
    components: { },
    watch: {
        initStatu(val) {
            this._saveWeek = this.saveWeek;
            val ? this.getDayList() : "";
        }
    },
    mounted() {
        //初始刷新日历列表
        this._saveWeek = this.saveWeek;
        this.getDayList();
    },
    methods: {
        getPrice() {
            let formData = this.$parent;
            let endTime = new Date().valueOf() + 24 * 3600 * 1000 * 29;
            let data = {
                arrCode: formData.endCity,
                depCode: formData.starCity,
                startDate: this.$moment().format("YYYY-MM-DD"),
                endDate: this.$moment(endTime).format("YYYY-MM-DD"),
                supplierType: 1
            };
            getClendarPrice(data).then(res => {
                if (res.result) {
                    this.events = res.result;
                    this.setPrice();
                }
            });
        },
        setPrice() {
            this.weekList.map(item => {
                this.events.map(day => {
                    if (item.date === day.depDate) {
                        item.price = day.ticketPrice;
                    }
                });
            });
        },
        cutShow(e) {
            if (!this.calendarStatu && this.fullCalendarShow) {
                this.fullCalendarShow = false;
            }
            if (e.target.id === "init") {
                this.fullCalendarShow = true;
            }
        },
        setTime(val) {
            this.weekList.map(item => {
                item.statu = false;
            });
            val.statu = true;
            this.$emit("clickDate", val.date);
        },
        dayClick(val) {
            let date1 = new Date(val.date).valueOf();
            let date = new Date().valueOf();
            if (date1 < date) return;
            this.fullCalendarShow = false;
            this._saveWeek = val.date.format("YYYY-MM-DD");
            this.getDayList();
            this.$emit("clickDate", val.date.format("YYYY-MM-DD"));
        },
        getDayList(val) {
            let now = new Date().valueOf();
            let location = this.locationDay(3);
            if (location - now <= 0) {
                this._getWeek();
                this.weekBtnStatu = false;
            } else {
                this._getWeek(location);
                this.weekBtnStatu = true;
            }
            //this.typePage ? this.getPrice() : "";
        },
        locationDay(val) {
            return Date.parse(this._saveWeek) - val * Math.floor(24 * 3600 * 1000);
        },
        _getWeek(val) {
            let arr = [];
            val ? val : "";
            for (let i = 0; i < 7; i++) {
                let dateTime = this.$moment(val)
                    .subtract(-i, "days")
                    .format("YYYY-MM-DD");
                let week = this.$moment(val).subtract(-i, "days")._d;
                week = week.getDay();
                week =
                    week === 0
                        ? "星期日"
                        : week === 1
                        ? "星期一"
                        : week === 2
                            ? "星期二"
                            : week === 3
                                ? "星期三"
                                : week === 4
                                    ? "星期四"
                                    : week === 5
                                        ? "星期五"
                                        : "星期六";
                let obj = {
                    week: week,
                    date: dateTime,
                    statu: false
                };
                arr.push(obj);
            }
            this.weekList = arr;
            if (this._saveWeek) {
                arr.map((item, index) => {
                    item.date === this._saveWeek ? (item.statu = true) : "";
                });
            }
            this.setPrice();
        },
        leftTime(val) {
            let _this = this;
            if (val == "next") {
                this.dayShow();
                this._getWeek(this.weekList[6].date);
                this.weekBtnStatu = true;
            } else if (val == "last") {
                if (!this.weekBtnStatu) return;
                this.dayShow();
                let date = this.$moment(this.weekList[0].date)
                    .subtract("days", 6)
                    .format("YYYY-MM-DD");
                let now = this.$moment().format("YYYY-MM-DD");
                let dayNum = Date.parse(date) - Date.parse(now);
                if (dayNum <= 0) {
                    this._getWeek(now);
                } else {
                    this._getWeek(date);
                }
                if (date.valueOf() <= now.valueOf()) {
                    this.weekBtnStatu = false;
                }
            }
        },
        dayShow() {
            this.show = false;
            setTimeout(() => {
                this.show = true;
            });
        },
        datedifference(sDate1, sDate2) {
            var dateSpan,
                tempDate,
                iDays,
                sDate1 = Date.parse(sDate1);
            sDate2 = Date.parse(sDate2);
            dateSpan = sDate2 - sDate1;
            dateSpan = Math.abs(dateSpan);
            iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
            return iDays;
        }
    }
};