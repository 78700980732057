const __request = require(`./__request/__request_contentType_json`);

// 航空公司查询
// 接口文档地址：http://183.58.24.99:9999/swagger-ui.html?tdsourcetag=s_pcqq_aiomsg#/
const buyer_dom_white_detail = (pParameter) => {
  // if (!pParameter) pParameter = {};
  // const data = {...pParameter}

  let data = {
    method: 'post',
    urlSuffix:'/buyer/dom/white/detail',
    data: pParameter,
    enable_error_alert: false // 临时隐藏
  };
  return __request(data)
}
module.exports = buyer_dom_white_detail;
