// buyer_dom_white_searchFlight 航班查询 http://192.168.0.33:8985/doc.html#/yinzhilv-flight/buyer-dom-white-controller/searchFlightUsingPOST
const __request = require(`./__request/flight-search/__request_contentType_json`);
const buyer_dom_white_searchFlight = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix:'/buyer/dom/white/searchFlight',
        data:data,
        // enable_error_alert: false // 临时隐藏
    }

    return __request(pParameter)
}
export default buyer_dom_white_searchFlight
