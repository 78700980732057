//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const citys = require(`@/lib/data-service/flight/flight_domestic_citys`);
const _ = require(`underscore`);

export default {
  data() {
    return {
      cityVlaue: null,
      cityId: null,
      listShow: false,
      cityListH: ["热门", "ABCD", "EFGH", "JKLM", "NOPQRS", "TUVWX", "YZ"], // 热门列表
      cityListHShow: 0,
      cityList: [],
      restaurants: "",
      cityListStatu: false,
      HotCity: []
    };
  },
  props: {
    titleP: {
      type: String,
      default: "出发城市"
    }
  },
  watch: {
    cityVlaue(val) {
      !val
        ? ((this.listShow = true), this.$refs.cityInput.focus())
        : this.querySearch();
    }
  },
  methods: {
    querySearch(queryString, cb) {
      if (!queryString) return;
      this.listShow = false;
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      results.length === 0 ? (this.cityVlaue = "") : "";
      cb(results);
    },
    createFilter(queryString) {
      let pattern2 = new RegExp("[A-Za-z]+");
      if (pattern2.test(queryString)) {
        return restaurant => {
          if (restaurant.spell && restaurant.code) {
            if (
              restaurant.spell
                .toUpperCase()
                .indexOf(queryString.toUpperCase()) != -1
            ) {
              return restaurant;
            } else if (
              restaurant.code.indexOf(queryString.toUpperCase()) != -1
            ) {
              return restaurant;
            }
          } else {
            return "";
          }
        };
      } else {
        return restaurant => {
          if (restaurant.cityName.indexOf(queryString) !== -1) {
            return restaurant;
          } else {
            return "";
          }
        };
      }
    },
    getstarCity(item) {
      this.cityId = item.airports
        ? item.code
        : item.cityCode
          ? item.cityCode
          : item.code;
      this.cityVlaue = item.name + "(" + item.code + ")";
    },
    getClickCity(item) {
      this.cityId = item.airports
        ? item.code
        : item.cityCode
          ? item.cityCode
          : item.code;
      this.cityVlaue = item.name + "(" + item.code + ")";
      this.listShow = false;
    },
    showCityList(item, key, index) {
      if (index < this.cityListH.length) {
        return this.cityListH[index].indexOf(key);
      }
    },
    cityListOpen(init) {
      init && !this.listShow ? (this.listShow = true) : (this.listShow = false);
    },
    clearCity() {
      if (!this.cityListStatu) {
        this.listShow = false;
      }
    },
    // 获取城市接口
    loadAllCity() {
      citys().then(res => {
        this.restaurants = [...res.citys];
        let cityList = [];
        for (let i = 0; i < res.citys.length; i++) {
          if (res.citys[i].airports === false) {
            cityList.push(res.citys[i]);
          }
          if (
            res.citys[i].isHot === "Y" &&
            this.HotCity.length < 23 &&
            res.citys[i].airports === false
          ) {
            this.HotCity.push(res.citys[i]);
          }
        }
        this.cityList = _.chain(cityList)
          .groupBy(function(m) {
            return m.initial;
          })
          .value();
      });
    }
  },
  created() {
    this.loadAllCity();
  },
  destroyed() {}
};
